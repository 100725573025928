<template>
  <div class="potential workBench">
    <div ref="navBar">
      <van-nav-bar title="工作台" class="nav_bar_title" fixed placeholder />
    </div>

    <div class="tab_wrapper">
      <van-tabs v-model="tabActive" line-width="20" line-height="5" title-active-color="rgb(255,81,45)" sticky scrollspy
        :offset-top="offsetTop" @scroll="scroll">
        <van-tab title="房屋状态" dot>
          <div class="title">
            <p class="titleText">房屋状态</p>
          </div>
          <div class="panel">
            <!--整租-->
            <div v-for="item in houseStatusList" :key="item.id"
              v-bind:class="{entrireTenancy:item.houseType==0,jointTenancy:item.houseType==1,apartmentJointTenancy:item.houseType==2}">
              <div
                v-bind:class="{eTStatusText:item.houseType==0,jTStatusText:item.houseType==1,aJTStatusText:item.houseType==2}">
                <div class="statusNum">{{item.houseTypeNum}}</div>
                <p v-if="item.houseType == '0'" class="houseResourceType">整租</p>
                <p v-if="item.houseType == '1'" class="houseResourceType">合租</p>
                <p v-if="item.houseType == '2'" class="houseResourceType">合租</p>
              </div>
              <div class="statusType">
                <div class="typeCommonStyle">
                  <div class="vacancyNum">{{item.vacancyNum}}</div>
                  <p class="typeTextCommonStyle">空置</p>
                </div>
                <div class="reserveStatus typeCommonStyle">
                  <div class="reserveNum">{{item.reserveNum}}</div>
                  <p class="typeTextCommonStyle">预定</p>
                </div>
                <div class="subletStatus typeCommonStyle">
                  <div class="subletNum">{{item.subletNum}}</div>
                  <p class="typeTextCommonStyle">转租</p>
                </div>
                <div class="willExpireStatus typeCommonStyle">
                  <div class="willExpireNum">{{item.willExpireNum}}</div>
                  <p class="typeTextCommonStyle">预到期</p>
                </div>
                <div class="fitmentingStatus typeCommonStyle">
                  <div class="fitmentingNum">{{item.fitmentingNum}}</div>
                  <p class="typeTextCommonStyle">装修中</p>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="流程审批"></van-tab>
        <van-tab title="资源情况">
          <div class="title">
            <p class="titleText">资源情况</p>
          </div>
          <div class="panel">
            <div class="info" style="borderBottom:1px solid rgb(250,250,250)">
              <van-row>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>潜房</span>
                  </p>
                </van-col>
                <van-col span="1">
                  <span class="line"></span>
                </van-col>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>跟进</span>
                  </p>
                </van-col>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>未实勘</span>
                  </p>
                </van-col>
                <van-col span="4">
                  <p>3</p>
                  <p>
                    <span>收房中</span>
                  </p>
                </van-col>
              </van-row>
            </div>
            <div class="info">
              <van-row>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>潜客</span>
                  </p>
                </van-col>
                <van-col span="1">
                  <span class="line"></span>
                </van-col>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>0跟进</span>
                  </p>
                </van-col>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>带看</span>
                  </p>
                </van-col>
                <van-col span="4">
                  <p>3</p>
                  <p>
                    <span>预约</span>
                  </p>
                </van-col>
                <van-col span="4">
                  <p>3</p>
                  <p>
                    <span>预定</span>
                  </p>
                </van-col>
              </van-row>
            </div>
          </div>
        </van-tab>
        <van-tab title="合同">
          <div class="title">
            <p class="titleText">合同</p>
          </div>
          <div class="panel">
            <div class="state">业主</div>
            <div class="info" style="borderBottom:1px solid rgb(250,250,250)">
              <van-row>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>待签字</span>
                  </p>
                </van-col>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>带交割</span>
                  </p>
                </van-col>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>租期中</span>
                  </p>
                </van-col>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>未交房</span>
                  </p>
                </van-col>
                <van-col span="4">
                  <p>3</p>
                  <p>
                    <span>预到期</span>
                  </p>
                </van-col>
              </van-row>
            </div>
          </div>
          <div class="panel">
            <div class="state">业主</div>
            <div class="info">
              <van-row>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>潜客</span>
                  </p>
                </van-col>
                <van-col span="1">
                  <span class="line"></span>
                </van-col>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>0跟进</span>
                  </p>
                </van-col>
                <van-col span="5">
                  <p>3</p>
                  <p>
                    <span>带看</span>
                  </p>
                </van-col>
                <van-col span="4">
                  <p>3</p>
                  <p>
                    <span>预约</span>
                  </p>
                </van-col>
                <van-col span="4">
                  <p>3</p>
                  <p>
                    <span>预定</span>
                  </p>
                </van-col>
              </van-row>
            </div>
          </div>
        </van-tab>
        <van-tab title="租后情况">
          <div class="title">
            <p class="titleText">租后情况</p>
          </div>
          <div class="panel">
            <!-- 装修 -->
            <div class="info">
              <van-row class="element_center">
                <van-col span="8">
                  <p class="total_color">
                    <van-icon name="brush-o" />装修
                  </p>
                </van-col>
                <van-col span="16">
                  <van-row>
                    <van-col span="8">
                      <p class="total_color">3</p>
                      <p>
                        <span>待处理</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>设计中</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>装配中</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>待验收</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>待决算</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>待验收</span>
                      </p>
                    </van-col>
                  </van-row>
                </van-col>
              </van-row>
            </div>
          </div>

          <div class="panel">
            <!-- 保洁 -->
            <div class="info">
              <van-row class="element_center">
                <van-col span="8">
                  <p class="total_color">保洁</p>
                </van-col>
                <van-col span="16">
                  <van-row>
                    <van-col span="8">
                      <p class="total_color">3</p>
                      <p>
                        <span>待处理</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>保洁中</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>待验收</span>
                      </p>
                    </van-col>
                  </van-row>
                </van-col>
              </van-row>
            </div>
          </div>

          <div class="panel">
            <!-- 配货 -->
            <div class="info">
              <van-row class="element_center">
                <van-col span="8">
                  <p class="total_color">
                    <van-icon name="logistics" />配货
                  </p>
                </van-col>
                <van-col span="16">
                  <van-row>
                    <van-col span="8">
                      <p class="total_color">3</p>
                      <p>
                        <span>待配货</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>配货中</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>待验收</span>
                      </p>
                    </van-col>
                  </van-row>
                </van-col>
              </van-row>
            </div>
          </div>
          <div class="panel">
            <!-- 投诉 -->
            <div class="info">
              <van-row class="element_center">
                <van-col span="8">
                  <p class="total_color">
                    <van-icon name="comment-o" />投诉
                  </p>
                </van-col>
                <van-col span="16">
                  <van-row>
                    <van-col span="8">
                      <p class="total_color">3</p>
                      <p>
                        <span>待处理</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>受理中</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>待回访</span>
                      </p>
                    </van-col>
                  </van-row>
                </van-col>
              </van-row>
            </div>
          </div>

          <div class="panel">
            <!-- 维修 -->
            <div class="info">
              <van-row class="element_center">
                <van-col span="8">
                  <p class="total_color">维修</p>
                </van-col>
                <van-col span="16">
                  <van-row>
                    <van-col span="8">
                      <p class="total_color">3</p>
                      <p>
                        <span>待处理</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>受理中</span>
                      </p>
                    </van-col>
                    <van-col span="8">
                      <p>3</p>
                      <p>
                        <span>待回访</span>
                      </p>
                    </van-col>
                  </van-row>
                </van-col>
              </van-row>
            </div>
          </div>
        </van-tab>
        <van-tab title="合同审批">
          <div class="title">
            <p class="titleText">合同审批</p>
          </div>
          <div class="panel redius">
            <van-row gutter="20">
              <van-col span="12">
                <div class="info">
                  <p>0</p>
                  <p>
                    <span>租客新签合同</span>
                  </p>
                </div>
              </van-col>
              <van-col span="12">
                <div class="info">
                  <p>0</p>
                  <p>
                    <span>业主新签合同</span>
                  </p>
                </div>
              </van-col>
            </van-row>
          </div>
        </van-tab>
        <van-tab title="个人中心">
          <div class="title">
            <p class="titleText">个人中心</p>
          </div>
          <div class="panel redius" style="marginBottom:5rem">
            <van-row gutter="20">
              <van-col span="8">
                <div class="info">
                  <p>
                    <span>icon</span>
                  </p>
                  <p>
                    <span>我的业绩</span>
                  </p>
                </div>
              </van-col>
              <van-col span="8">
                <div class="info">
                  <p>
                    <span>icon</span>
                  </p>
                  <p>
                    <span>我的请假</span>
                  </p>
                </div>
              </van-col>
              <van-col span="8">
                <div class="info">
                  <p>
                    <span>icon</span>
                  </p>
                  <p>
                    <span>我的工资</span>
                  </p>
                </div>
              </van-col>
            </van-row>
          </div>
        </van-tab>
      </van-tabs>
      <!-- 自定义菜单栏 -->
      <van-dropdown-menu class="drop_menu">
        <van-dropdown-item ref="item">
          <div slot="title">
            <div><span class="menu_top_icon"></span><span class="menu_icon"></span></div>
          </div>
          <van-grid :column-num="5">
            <van-grid-item v-for="tabBar in tabs" :key="tabBar.name" :text="tabBar.name"
              :class="{dot:tabBar.isDot,totalColor:tabBar.isActive}" />
          </van-grid>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>

    <!-- 标签栏 -->
    <van-tabbar v-model="active"
      fixed
      placeholder 
      route 
      active-color="#f00" 
    >
      <van-tabbar-item  to="/workbench" icon="home-o">工作台</van-tabbar-item>
      <van-tabbar-item  icon="search">数据</van-tabbar-item>
      <van-tabbar-item  icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
  import {
    NavBar,
    Tab,
    Tabs,
    Row,
    Col,
    DropdownMenu,
    DropdownItem,
    Tag,
    Tabbar,
    TabbarItem,
    Icon,
    Grid,
    GridItem
  } from "vant";
  export default {
    data() {
      return {
        active: 0,
        tabs: [{
            name: '房屋状态',
            isDot: false,
            isActive: true,
          },
          {
            name: '审批流程',
            isDot: false,
            isActive: false
          }, {
            name: '资源情况',
            isDot: false,
            isActive: false
          }, {
            name: '合同',
            isDot: true,
            isActive: false
          }, {
            name: '租后情况',
            isDot: false,
            isActive: false
          }, {
            name: '合同审批',
            isDot: false,
            isActive: false
          }, {
            name: '个人中心',
            isDot: false,
            isActive: false
          }
        ],
        offsetTop: '50',
        tabActive: 0,
        value1: 0,
        option1: [{
            text: "全部商品",
            value: 0
          },
          {
            text: "新款商品",
            value: 1
          },
          {
            text: "活动商品",
            value: 2
          }
        ],

        houseStatusList: [{
            id: 1,
            houseType: 0,
            houseTypeNum: 25,
            houseChildType: 1,
            vacancyNum: 3,
            reserveNum: 6,
            subletNum: 0,
            willExpireNum: 14,
            fitmentingNum: 2
          },
          {
            id: 2,
            houseType: 1,
            houseTypeNum: 30,
            houseChildType: 1,
            vacancyNum: 3,
            reserveNum: 6,
            subletNum: 0,
            willExpireNum: 14,
            fitmentingNum: 2
          },
          {
            id: 3,
            houseType: 2,
            houseTypeNum: 20,
            houseChildType: 4,
            vacancyNum: 3,
            reserveNum: 6,
            subletNum: 0,
            willExpireNum: 14,
            fitmentingNum: 2
          }
        ]
      };
    },
    methods: {
      // 滚动时获取nav高度并赋值
      scroll() {
        this.offsetTop = this.$refs.navBar.clientHeight
      }
    },
    components: {
      [NavBar.name]: NavBar,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Row.name]: Row,
      [Col.name]: Col,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [Tag.name]: Tag,
      [Tabbar.name]: Tabbar,
      [TabbarItem.name]: TabbarItem,
      [Icon.name]: Icon,
      [Grid.name]: Grid,
      [GridItem.name]: GridItem
    },
  };
</script>
<style lang="less" scoped>
  @redius: 8px;
  @spanColor: rgb(169, 169, 169);

  .potential {
    width: 375px;
    background-color: rgb(250, 250, 250);
    font-size: 12px;
    box-sizing: border-box;
    overflow: hidden;

    p {
      margin: 0;
    }

    .title {
      margin-top: 0.5rem;
      width: 5rem;
      height: 1.8rem;
      background-image: url("../../assets/images/head-quotation-mark.png");
      background-size: 28% 96%;
      background-repeat: no-repeat;

      .titleText {
        margin-left: 0.6rem;
        padding-top: 0.8rem;
        font-size: 0.6rem;
        color: #222222;
        font-family: PingFangSC-Semibold;
      }
    }

    .panel {
      width: 9rem;
      margin: 0 auto;
      background-color: rgb(250, 250, 250);
      border-radius: @redius;
      margin-top: 15px;
      overflow: hidden;
      position: relative;

      &.redius {
        .info {
          border-radius: @redius;
        }
      }

      .state {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 20px;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
        width: 60px;
        display: block;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left,
            rgba(255, 194, 116, 1),
            rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right,
            rgba(255, 194, 116, 1),
            rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right,
            rgba(255, 194, 116, 1),
            rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right,
            rgba(255, 194, 116, 1),
            rgba(255, 93, 59, 1));
        border-top-left-radius: 16px;
        border-bottom-right-radius: 16px;
        color: rgb(255, 255, 255);
        text-align: center;
        transform: scale(0.5);
        transform-origin: 0% 0%;
        font-family: sans-serif;
        padding: 0 5px;
      }

      p {
        margin: 0;
      }

      // 房屋状态循环
      .entrireTenancy {
        /*background-color: #FEF3EF;*/
        background-color: rgba(184, 184, 184, 0.2);
        box-shadow: 0 0.08rem 0.05rem #f0e6e4;
        width: 9rem;
        height: 3.8rem;
        border-radius: 0.3rem;
        text-align: center;
      }

      .jointTenancy {
        background-color: #eaf4fe;
        box-shadow: 0 0.08rem 0.05rem #d3dce5;
        width: 9rem;
        height: 3.8rem;
        border-radius: 0.3rem;
        margin-top: 0.5rem;
        text-align: center;
      }

      .apartmentJointTenancy {
        background-color: #f0fce6;
        box-shadow: 0 0.08rem 0.05rem #e1f2ce;
        width: 9rem;
        height: 3.8rem;
        border-radius: 0.3rem;
        margin-top: 0.5rem;
        text-align: center;
      }

      .eTStatusText {
        float: left;
        width: 3.5rem;
        height: 3rem;
        font-size: 0.5rem;
        margin: 0.4rem 0 0 0;
        border-right: 2px solid #fae0cf;
      }

      .jTStatusText {
        float: left;
        width: 3.5rem;
        height: 3rem;
        font-size: 0.5rem;
        margin: 0.4rem 0 0 0;
        border-right: 2px solid #d6e7fa;
      }

      .aJTStatusText {
        float: left;
        width: 3.5rem;
        height: 3rem;
        font-size: 0.5rem;
        margin: 0.4rem 0 0 0;
        border-right: 2px solid #c1e9cc;
      }

      .statusNum {
        font-weight: 500;
        font-size: 1.2rem;
        padding-top: 0.4rem;
      }

      .statusType {
        float: left;
        width: 4.5rem;
        height: 3.4rem;
        margin-top: 0.3rem;
        padding-left: 0.6rem;

        .typeCommonStyle {
          float: left;
          margin-top: 0.2rem;
          margin-right: 0.2rem;
          height: 1.5rem;
          width: 1.3rem;
          font-size: 0.6rem;
          font-weight: 500;
        }

        .typeTextCommonStyle {
          color: #6a6e68;
          font-size: 0.3rem;
          margin: 0 0 0 0;
        }

        .houseResourceType {
          margin-top: 0;
        }
      }

      // 资源情况
      .info {
        text-align: center;
        font-size: 0.6rem;
        background: rgb(255, 255, 255);
        padding: 15px;

        .element_center {
          display: flex;
          align-items: center;

          p {
            margin: 10px 0;
          }
        }

        p {
          font-weight: bolder;

          span {
            color: @spanColor;
            font-size: 0.3rem;
          }
        }

        .line {
          display: block;
          width: 2px;
          height: 40px;
          background-color: rgb(250, 250, 250);
          margin-top: 5px;
        }

        .total_color {
          color: rgba(255, 93, 59, 1);
        }
      }
    }

    .tab_wrapper {
      position: relative;

      .drop_menu {
        position: fixed;
        top: 71px;
        right: 0px;
        z-index: 9999;

        .menu_icon {
          background: url(../../assets/images/menu.png) no-repeat;
          background-size: cover;
          width: 18px;
          height: 18px;
          display: block;
          position: relative;
          box-shadow: -5px 0 10px 5px rgb(255, 255, 255);
        }

        .drop_menu_body {
          padding: 15px;
          text-align: center;

          p {
            padding: 10px 0px;
          }
        }
      }
    }

  }
</style>